.home-container {
  text-align: center;
  color: #333;
  padding: 2rem;
  background: #f9f9f9;
}

.home-container h1 {
  font-size: 2.5rem;
  color: #4CAF50;
  margin-bottom: 1rem;
}

.home-container p {
  font-size: 1.2rem;
  margin-bottom: 2rem;
}

.note {
  background: #ffeb3b;
  padding: 1rem;
  margin-bottom: 2rem;
  border-radius: 5px;
  font-size: 1rem;
  color: #333;
}

.features {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.feature {
  background: #fff;
  padding: 1rem;
  margin: 1rem;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 250px;
  transition: transform 0.3s;
}

.feature:hover {
  transform: scale(1.05);
}

.feature h2 {
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
  color: #4CAF50;
}

.feature p {
  font-size: 1rem;
  color: #666;
}

.create-route-container {
  max-width: 1200px;
  margin: 2em auto;
  background-color: #fff;
  padding: 2em;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

h1, h2 {
  color: #333;
}

input[type="text"], select, textarea {
  width: calc(100% - 22px);
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

textarea {
  height: 100px;
}

.dropdown-group {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  margin-bottom: 20px;
}

.control-group {
  display: auto;
  flex-direction: column;
  align-items: normal;
  margin-right: 10px;
  margin-bottom: 10px;
}

button {
  background-color: #28a745;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
}

button:hover {
  background-color: #218838;
}

.undo-button {
  background-color: #ffc107;
}

.undo-button:hover {
  background-color: #e0a800;
}

.image-selection {
  display: wrap;
  justify-content: center;
  margin-bottom: 20px;
}

.image-preview {
  width: 80px;
  height: 60px;
  object-fit: cover;
  border: 2px solid transparent;
  cursor: pointer;
}

.image-preview.selected {
  border-color: #007bff;
}

.hold-container {
  text-align: center;
}

.image-wrapper {
  width: 100%;
  height: auto;
  position: relative;
  overflow: hidden;
}

.selected-image {
  width: 100%;
  height: auto;
  max-height: 5000px; /* Adjust this value to limit the height of large images */
  object-fit: contain; /* Ensures the entire image fits within the wrapper */
}

.hold {
  position: absolute;
  border-radius: 50%;
  transform: translate(-50%, -50%);
}


.hold.circle {
  border-radius: 50%;
}

.hold.square {
  border-radius: 0;
}

.hold.filled {
  background-color: red;
}

.hold.outline {
  background-color: transparent;
  border-width: 2px;
  border-style: solid;
}

.hold.small {
  width: 4px;
  height: 4px;
}

.hold.medium {
  width: 8px;
  height: 8px;
}

.hold.large {
  width: 16px;
  height: 16px;
}

.hold.start {
  background-color: green;
}

.hold.top {
  background-color: blue;
}

.hold-label {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
  font-weight: bold;
  font-size: 0.75em;
  pointer-events: none;
}

.button-group {
  text-align: center;
  margin-top: 20px;
}
.image-wrapper {
  position: relative;
}

.drawing-canvas {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}

.dropdown-group {
  z-index: 2;
  position: relative;
}

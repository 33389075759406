.home-container {
    text-align: center;
    color: #333;
    padding: 2rem;
    background: #f9f9f9;
  }
  
  .home-container h1 {
    font-size: 2.5rem;
    color: #4CAF50;
    margin-bottom: 1rem;
  }
  
  .home-container p {
    font-size: 1.2rem;
    margin-bottom: 2rem;
  }
  
  .note {
    background: #ffeb3b;
    padding: 1rem;
    margin-bottom: 2rem;
    border-radius: 5px;
    font-size: 1rem;
    color: #333;
  }
  
  .features {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
  }
  
  .feature {
    background: #fff;
    padding: 1rem;
    margin: 1rem;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 250px;
    transition: transform 0.3s;
  }
  
  .feature:hover {
    transform: scale(1.05);
  }
  
  .feature h2 {
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
    color: #4CAF50;
  }
  
  .feature p {
    font-size: 1rem;
    color: #666;
  }
  .feature-link {
    text-decoration: none;
    color: inherit;
  }
  
  .feature-link:hover {
    text-decoration: underline;
  }
  .author-name {
    position: absolute;
    bottom: 10px;
    right: 10px;
    font-size: 14px;
    color: #555;
  }
.view-routes-container {
  max-width: 1200px;
  margin: 2em auto;
  background-color: #fff;
  padding: 2em;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.filters {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.route-cards-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.route-card {
  border: 1px solid #ccc;
  padding: 10px;
  margin: 10px;
  cursor: pointer;
  transition: transform 0.2s;
  position: relative;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background: #fff;
  width: 200px; /* Smaller width for the preview */
}

.route-card:hover {
  transform: scale(1.05);
}

.route-card-image-wrapper {
  position: relative;
  width: 100%;
  padding-top: 145%; /* 4:3 Aspect Ratio */
  overflow: hidden;
  border-bottom: 1px solid #ccc;
}

.route-card-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.route-card h3 {
  margin: 10px 0 5px 0;
  color: #333;
  font-size: 1rem; /* Smaller font size */
}

.route-card p {
  margin: 5px 0;
  color: #666;
  font-size: 0.875rem; /* Smaller font size */
}

@import url('https://fonts.googleapis.com/css2?family=Press+Start+2P&display=swap');

/* Modal Overlay */
.route-modal {
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.4);
}

/* Modal Content */
.route-modal-content {
  background-color: #ffffff;
  margin: 5% auto;
  padding: 0%; /* Keep padding 0 for the container */
  border: 1px solid #888;
  width: 90%;
  max-width: 800px;
  border-radius: 10px;
  position: relative;
  transform-style: preserve-3d;
  transition: transform 0.6s;
}

/* Card Front and Back */
.card-front,
.card-back {
  position: relative;
  width: 100%;
  height: 100%;
  backface-visibility: visible;
  border-radius: 10px;
  padding: 20px; /* Add padding inside the card */
}

.card-front {
  background-color: #ffffff;
  transform: rotateY(0deg);
}

.route-modal-content.flipped .card-front {
  transform: rotateY(180deg);
}

.route-modal-content.flipped .card-back {
  transform: rotateY(0deg);
}

/* Close Button */
.close {
  color: #000000;
  float: right;
  font-size: 28px;
  font-weight: bold;
  cursor: pointer;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
}

/* Image Wrapper */
.image-wrapper {
  position: relative;
  width: 100%;
  max-width: 800px; /* Constrain image width */
  aspect-ratio: 1 / 1.45; /* Maintain 145% aspect ratio */
  margin: 0 auto;
  overflow: hidden;
}

.selected-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: contain; /* Ensure proportional scaling */
}

/* Holds Styling */
.hold {
  position: absolute;
  transform: translate(-50%, -50%);
  border-radius: 50%; /* Default shape */
  border-width: 2px;
  border-style: solid;
  pointer-events: none; /* Prevent interfering with user interactions */
  transition: transform 0.2s ease-in-out;
}

.hold.circle {
  border-radius: 50%;
}

.hold.square {
  border-radius: 0;
}

.hold.filled {
  background-color: red;
}

.hold.outline {
  background-color: transparent;
}

.hold.small {
  width: 8px;
  height: 8px;
}

.hold.medium {
  width: 16px;
  height: 16px;
}

.hold.large {
  width: 24px;
  height: 24px;
}

.hold.start {
  background-color: green;
}

.hold.top {
  background-color: blue;
}

.hold:hover {
  transform: translate(-50%, -50%) scale(1.2); /* Slight scaling on hover */
}

/* Hold Labels */
.hold-label {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
  font-weight: bold;
  font-size: 0.75em;
  pointer-events: none;
}

/* Button Styling */
.button-group {
  display: flex;
  gap: 1rem;
  margin-top: 20px;
}

button {
  background-color: #007bff;
  color: #fff;
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  font-size: 14px;
  cursor: pointer;
  text-align: center;
}

button:hover {
  background-color: #0056b3;
}

/* Arcade Leaderboard (Backside) */
.card-back {
  background-color: #222;
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px; /* Add padding inside the back side */
  transform: rotateY(180deg);
  text-align: center;
}

.card-back h3 {
  font-family: 'Press Start 2P', cursive; /* Arcade style font */
  font-size: 1.5em;
  margin-bottom: 20px;
}

.card-back ol {
  list-style-type: none;
  padding: 0;
  font-family: 'Press Start 2P', cursive; /* Arcade style font */
  font-size: 1em;
  color: #ffdd00; /* Gold color for leaderboard text */
  text-align: left;
  max-height: 300px;
  overflow-y: auto;
}

.card-back li {
  margin-bottom: 10px;
}

.card-back button {
  background-color: #007bff;
  color: #fff;
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  font-size: 14px;
  cursor: pointer;
  text-align: center;
  margin-top: 20px;
}

.card-back button:hover {
  background-color: #0056b3;
}
